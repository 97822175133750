import { SessionAccessRuleStatus as D } from "@waitroom/models";
import { aiApiService as k, buildSuccessRequestResponse as P, calendarApiService as te, sessionApiService as l, cmsApiService as j, integrationsApiService as Y, meetingMemoryApiService as H, recordingApiService as z, sessionAccessApiService as Q, userApiService as I, authApiService as se, subscriptionPlanApiService as ne, tokenApiService as re, userMeetingTypesService as oe } from "@waitroom/common-api";
import { applyPatch as ue, deepClone as O } from "fast-json-patch";
import { useInfiniteQuery as ae, useQuery as ie, useQueryClient as ye, QueryObserver as $ } from "@tanstack/react-query";
import { useEffect as T, useRef as Ee, useState as W, useCallback as _e } from "react";
const J = (e) => typeof e == "function", F = ({
  client: e,
  key: t,
  fn: n,
  skipEmpty: s
}) => {
  const r = e.getQueryData(t);
  s && r === void 0 || e.setQueryData(t, n(r));
}, v = (e) => F({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: J(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), Se = {
  update: v,
  addToArray: (e) => v({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...n
  }) => v({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...n
  }) => v({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => F({
    ...e,
    fn: (t) => {
      var s, r, o;
      const n = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return n.pages[0] = {
        ...n.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(s = n.pages[0]) == null ? void 0 : s.data,
          data: e.updateFn((o = (r = n.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, n;
    }
  }),
  updateInPages: (e) => F({
    ...e,
    fn: (t) => {
      var s, r;
      if (!t || !((s = t.pages) != null && s.length)) return t;
      const n = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < n.pages.length; o++) {
        const u = (r = n.pages[o]) == null ? void 0 : r.data, a = u == null ? void 0 : u.data;
        if (!a) continue;
        const i = e.updateFn(a);
        if (u !== i && (n.pages[o] = {
          ...n.pages[o],
          data: {
            ...u,
            data: i
          }
        }, e.stopOnChange))
          break;
      }
      return n;
    }
  })
}, ge = (e) => F({
  ...e,
  fn: (t) => {
    var n;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: J(e.dataOrFn) ? e.dataOrFn((n = t == null ? void 0 : t.data) == null ? void 0 : n.data) : e.dataOrFn
      }
    };
  }
}), me = {
  update: ge
}, C = {
  update: F,
  request: Se,
  apiRequest: me
}, w = "private", X = "calendarEvent", ce = "cmsPage", Ce = "cmsPages", mt = "currentUser", pe = "dashboardPastSessions", Ae = "dashboardSessions", ct = "featuredSessions", Ct = "integrationsConnections", pt = "integrationsDirectURL", At = "integrationsGetAction", le = "integrationsProviders", de = "meetingMemoryMessages", lt = "meetingMemorySuggestions", Re = "meetingMemoryThread", Oe = "meetingMemoryThreads", fe = "paymentDetails", Te = "recording", dt = "recordingStatus", Ke = "session", Rt = "sessionAccessRule", L = "sessionAccessRules", De = "sessionAiFeed", Fe = "sessionEndSummary", Pe = "sessionEpisodes", Ot = "sessionPendingRulesStats", Qe = "sessionPresence", Z = "sessionRequestAccess", ft = "sessionViewers", Me = "sessionCards", h = "streamToken", ve = "listeningModeSpeakers", qe = "subscriptionPlans", Ne = "subscriptionReceipts", Ye = "user", He = "integrationsGetByOwnerSessionRecurrence", Ie = "userMeetingTypes-v2", Tt = "salesforceProvider", Kt = "salesforceGetBindingById", Dt = "salesforceGetAccountById", Ft = "salesforceGetOpportunityById", Pt = "salesforceListBindableRecordsAsOptions", Qt = "salesforceGetContacts", Mt = "salesforceGetLeadById", vt = "hubspotProvider", qt = "hubspotGetBindingById", Ge = "hubspotGetCompanyById", Nt = "hubspotGetDealById", Yt = "hubspotListBindableRecordsAsOptions", Ht = "hubspotGetContacts", It = "hubspotGetContactById", Gt = (e, t) => [w, ...e, t], q = (e, t = "") => [
  De,
  e,
  t
], U = (e, t = "") => [
  Z,
  e,
  t,
  D.IN_REVIEW
], be = (e, t, n) => [Z, e, t, n || ""], Be = (e, t, n) => [Ke, e, t || "", n || ""], Le = (e, t = "") => [
  Qe,
  e,
  t
], Ue = (e) => [Ye, e], x = (e) => [
  Oe,
  e
], bt = (e) => [Re, e], f = (e) => [
  de,
  e
], Bt = (e, t) => [
  ve,
  e,
  t
], ke = (e, t) => [
  Fe,
  e,
  t
], we = {
  add: ({ client: e, data: t, sessionId: n, recurrenceId: s }) => {
    t && C.request.addToArray({
      client: e,
      key: q(n, s),
      data: [t]
    });
  },
  update: ({ client: e, data: t, sessionId: n, recurrenceId: s }) => {
    t && C.request.update({
      client: e,
      key: q(n, s),
      dataOrFn: (r) => r ? (r.messages = r.messages.map((o) => o.id === t.id ? t : o), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({ client: e, sessionId: t, recurrenceId: n, id: s }) => {
    C.request.removeFromArray({
      client: e,
      key: q(t, n),
      id: s,
      keyId: "id"
    });
  }
}, Lt = {
  ...we
}, xe = {
  addMessage: ({ client: e, data: t, id: n }) => {
    t && C.apiRequest.update({
      client: e,
      key: f(n),
      dataOrFn: (s) => ({
        hasMore: !1,
        ...s,
        messages: [...(s == null ? void 0 : s.messages) || [], t],
        total: ((s == null ? void 0 : s.total) || 0) + 1
      })
    });
  },
  updateMessage: ({ client: e, data: t, id: n }) => {
    t && C.apiRequest.update({
      client: e,
      key: f(n),
      dataOrFn: (s) => s != null && s.messages ? {
        ...s,
        messages: s.messages.map((r) => r.id === t.id ? { ...r, ...t } : r)
      } : s
    });
  },
  updateLastAiMessage: ({ client: e, data: t, id: n }) => {
    t && C.apiRequest.update({
      client: e,
      key: f(n),
      dataOrFn: (s) => {
        var i;
        const r = s && {
          ...s,
          messages: s.messages ? [...s.messages] : void 0
        };
        if (!(r != null && r.messages)) return s;
        const o = r.messages.findLastIndex(
          (_) => (!t.id || _.id === t.id) && _.role === "ai"
        );
        if (o === -1) return s;
        const u = r.messages[o], a = { ...u, ...t, id: t.id || u.id };
        return t.content && (a.content = `${u.content || ""}${t.content}`), t.todo && (a.todo = `${u.todo || ""}${t.todo}`), t.progress && (a.progress = `${u.progress || ""}${t.progress}`), r.messages[o] = a, !((i = a.content) != null && i.length) && t.isComplete && r.messages.splice(o, 1), r;
      }
    });
  },
  removeMessage: ({ client: e, id: t, messageId: n }) => {
    C.apiRequest.update({
      client: e,
      key: f(t),
      dataOrFn: (s) => {
        var u;
        if (!(s != null && s.messages)) return s;
        const r = s.messages.length, o = (u = s.messages) == null ? void 0 : u.filter((a) => a.id !== n);
        return {
          ...s,
          messages: o,
          total: Math.max((s.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  },
  removeLastMessage: ({ client: e, id: t }) => {
    C.apiRequest.update({
      client: e,
      key: f(t),
      dataOrFn: (n) => n != null && n.messages ? {
        ...n,
        messages: n.messages.slice(0, -1),
        total: Math.max((n.total || 0) - 1, 0)
      } : n
    });
  },
  removeThread: ({ client: e, id: t, userId: n }) => {
    C.apiRequest.update({
      client: e,
      key: x(n),
      dataOrFn: (s) => {
        var u;
        if (!(s != null && s.threads)) return s;
        const r = s.threads.length, o = (u = s.threads) == null ? void 0 : u.filter((a) => a.id !== t);
        return {
          ...s,
          threads: o,
          total: Math.max((s.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  }
}, Ut = xe;
let R;
const kt = (e) => {
  R = e.logger;
}, Ve = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, wt = (e) => Ve(e.data), xt = (e, t = 0) => {
  var n, s, r;
  return (r = (s = (n = e.data) == null ? void 0 : n.pages[t]) == null ? void 0 : s.data) == null ? void 0 : r.data;
}, K = (e, t, n = void 0, ...s) => {
  try {
    return ue(e, t, ...s).newDocument;
  } catch (r) {
    return R == null || R.logLvl(3, r, e, t), R == null || R.report(r), n;
  }
}, Vt = (e, t, n) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const s = e.path.split("/").slice(1, -1);
  let r = n;
  for (const o of s)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, $t = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, V = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), y = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  0: 0
}, g = [""], A = () => {
}, m = V(!0), G = {
  "7d": m(y["7d"]),
  "3d": m(y["3d"]),
  "1d": m(y["1d"]),
  "6h": m(y["6h"]),
  "1h": m(y["1h"]),
  "30m": m(y["30m"]),
  "15m": m(y["15m"]),
  "10m": m(y["10m"]),
  "5m": m(y["5m"]),
  "3m": m(y["3m"]),
  "1m": m(y["1m"]),
  "30s": m(y["30s"]),
  "15s": m(y["15s"]),
  "3s": m(y["3s"]),
  0: m(0)
}, c = V(!1), p = {
  "7d": c(y["7d"]),
  "3d": c(y["3d"]),
  "1d": c(y["1d"]),
  "6h": c(y["6h"]),
  "1h": c(y["1h"]),
  "30m": c(y["30m"]),
  "15m": c(y["15m"]),
  "10m": c(y["10m"]),
  "5m": c(y["5m"]),
  "3m": c(y["3m"]),
  "1m": c(y["1m"]),
  "30s": c(y["30s"]),
  "15s": c(y["15s"]),
  "3s": c(y["3s"]),
  0: c(0)
}, d = (e) => ({
  ...V(!1)(e),
  refetchOnReconnect: !0
}), Wt = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onFetched: s,
  onPatch: r,
  options: o,
  params: u
}) => {
  const a = !!e, i = a ? q(e, t) : g;
  return {
    ...d(y[0]),
    ...o,
    queryKey: i,
    queryFn: a ? ({ signal: _ }) => k.getFeed(e, t, {
      ...u,
      signal: _,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...u == null ? void 0 : u.params
      },
      onFetched: (E) => {
        n.setQueryData(i, E), s && s(E);
      },
      onPatch: (E) => {
        r && r(E), n.setQueryData(
          i,
          (S = P({
            messages: [],
            totalSummaryCount: 0
          })) => K(O(S), E) ?? S
        );
      }
    }) : A
  };
}, jt = ({
  sessionId: e,
  recurrenceId: t,
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => k.event(e, t, {
    ...s,
    data: r
  })
}), zt = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: ke(e, t),
  queryFn: () => k.getSummary(e, t),
  ...p["5m"]
}), Jt = (e) => [X, e], Xt = (e) => ({
  queryKey: [X, e],
  queryFn: () => te.getCalndrLinkEvent({ data: e })
}), $e = (e, t) => [
  Me,
  e,
  t
], Zt = (e, t, n, s, r) => {
  const o = !!e && !!t, u = o ? $e(e, t) : g;
  return {
    queryKey: u,
    enabled: o,
    ...d(y[0]),
    queryFn: o ? () => l.cards(e, t, {
      onFetched: (a) => {
        n.setQueryData(u, a);
      },
      onPatch: (a) => {
        n.setQueryData(
          u,
          (i = {}) => K(O(i), a) ?? i
        );
      },
      ...s
    }) : A,
    ...r
  };
}, We = (e) => [ce, e], ht = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? We(e) : g,
  queryFn: () => j.getPage(e || ""),
  ...p[t ? 0 : "5m"],
  enabled: !!e
}), je = (e) => [Ce, e], es = ({
  types: e,
  isPreview: t
}) => ({
  queryKey: je(e),
  queryFn: async () => {
    var o;
    const n = await j.getPages(e);
    if (!((o = n == null ? void 0 : n.data) != null && o.result)) throw n;
    const r = n.data.result.reduce(
      (u, a) => a.type ? a.type === "general" ? (a.category && (u.general[a.category] = [...u[a.type][a.category] || [], a]), u) : (u[a.type] = [...u[a.type] || [], a], u) : u,
      {
        general: {
          // here because of the order
          features: [],
          solutions: [],
          compare: [],
          resources: [],
          product: [],
          none: []
        }
      }
    );
    return {
      ...n,
      grouped: r
    };
  },
  ...p[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), ze = (e, t) => [He, e, t], ts = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: n,
  options: s,
  params: r
}) => {
  const o = !!e && !!t, u = o ? ze(e, t) : g;
  return {
    queryKey: u,
    enabled: o,
    ...d(y[0]),
    ...s,
    queryFn: o ? () => Y.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        onFetched: (a) => {
          n.setQueryData(u, a);
        },
        onPatch: (a) => {
          n.setQueryData(
            u,
            (i = {}) => K(O(i), a) ?? i
          );
        },
        ...r
      }
    ) : A
  };
}, ss = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: n,
    sessionRecurrenceId: s,
    record: { id: r, type: o }
  }) => Y.hubspot.createBinding({
    ...e,
    data: {
      sessionId: n,
      sessionRecurrenceId: s,
      record: {
        id: r,
        type: o
      }
    }
  })
}), Je = (e) => [Ge, e], ns = ({
  id: e,
  params: t,
  ...n
}) => {
  const s = !!e;
  return {
    ...p["1m"],
    ...n,
    enabled: s,
    queryKey: s ? Je(e) : g,
    queryFn: s ? () => Y.hubspot.getCompanyById(e) : A
  };
}, Xe = [le], rs = {
  queryKey: Xe,
  queryFn: Y.getProviders,
  ...p["1h"]
}, os = ({
  userId: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? x(e) : g;
  return {
    ...G["3m"],
    ...t,
    queryKey: r,
    queryFn: s ? () => H.threads(n) : A
  };
}, us = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? x(e) : g;
  return {
    ...p["3m"],
    ...t,
    queryKey: r,
    enabled: s,
    queryFn: s ? () => H.thread(e, n) : A
  };
}, as = ({
  id: e,
  options: t,
  params: n,
  onFetch: s
}) => {
  const r = !!e, o = r ? f(e) : g;
  return {
    ...p["3m"],
    queryKey: o,
    enabled: r,
    ...t,
    queryFn: r ? () => (s == null || s(), H.threadMessages(e, n)) : A
  };
}, is = ({
  id: e,
  options: t,
  params: n
}) => ({
  ...t,
  mutationFn: (s) => H.deleteThread(e, {
    ...n,
    data: s
  })
}), Ze = (e) => [
  Pe,
  e
], ys = (e) => ({
  queryKey: e ? Ze(e) : g,
  queryFn: e ? () => z.getEpisodes(e) : A,
  ...p["5m"],
  enabled: !!e
}), he = (e, t) => [
  Te,
  e,
  t
], Es = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? he(e, t) : g,
    ...p["15m"],
    queryFn: n ? () => z.getRecording(e, t) : A,
    enabled: n
  };
}, _s = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const u = !!e, a = u ? U(e, t) : g;
  return {
    ...d(y[0]),
    ...r,
    queryKey: a,
    enabled: u,
    queryFn: ({ signal: i }) => Q.inReview({
      ...o,
      signal: i,
      params: {
        ...o == null ? void 0 : o.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      },
      onFetched: (_) => {
        n.setQueryData(a, _);
      },
      onPatch: (_) => {
        s && s(_), n.setQueryData(
          a,
          (E = P(
            void 0
          )) => K(O(E), _) ?? E
        );
      }
    })
  };
}, Ss = ({
  userId: e,
  sessionId: t,
  recurrenceId: n = "",
  queryClient: s,
  onPatch: r,
  options: o,
  params: u
}) => {
  const a = !!e && !!t, i = a ? be(e, t, n) : g;
  return {
    ...d(y[0]),
    ...o,
    queryKey: i,
    enabled: a,
    queryFn: a ? ({ signal: _ }) => Q.userSessionRequest({
      ...u,
      signal: _,
      params: {
        ...u == null ? void 0 : u.params,
        sessionID: t,
        ...n ? { sessionRecurrenceID: n } : {}
      },
      onFetched: (E) => {
        s.setQueryData(i, E);
      },
      onPatch: (E) => {
        r && r(E), s.setQueryData(
          i,
          (S = P(
            void 0
          )) => K(O(S), O(E)) ?? S
        );
      }
    }) : A
  };
}, gs = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (n) => Q.requestAccess({
    ...t,
    data: n
  })
}), ms = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (n) => Q.update({
    ...t,
    data: n
  })
}), et = (e, t) => [
  L,
  e,
  t,
  D.GRANTED
], cs = (e, t, n) => ({
  queryKey: et(e, t),
  queryFn: () => Q.getViewer({
    params: {
      limit: 50,
      ...n,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: D.GRANTED
    }
  }),
  ...G["3m"]
}), Cs = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: n,
  onFetched: s,
  onPatch: r,
  onError: o,
  options: u,
  params: a,
  queryClient: i
}) => {
  const _ = e && n ? Be(e, t, n) : g;
  return {
    ...d(y[0]),
    // force no caching because of problems with redux sync
    initialData: void 0,
    retry: (E, S) => (S == null ? void 0 : S.code) !== 401,
    enabled: !!e && !!n,
    ...u,
    queryKey: _,
    queryFn: e ? ({ signal: E }) => l.getById(e, {
      ...a,
      signal: E,
      onFetched: (S) => {
        i.setQueryData(_, S), s && s(S);
      },
      onPatch: r,
      onError: o
    }) : void 0
  };
}, ps = ({
  sessionId: e,
  recurrenceId: t = "",
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => l.update(e, t, {
    ...s,
    data: r
  })
}), As = ({
  sessionId: e
}) => ({
  mutationFn: () => l.end(e)
}), ls = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const u = e ? Le(e, t) : g;
  return {
    ...d(y["3s"]),
    enabled: !!e,
    ...r,
    queryKey: u,
    queryFn: e ? ({ signal: a }) => l.presence(e, {
      ...o,
      signal: a,
      onFetched: (i) => {
        n.setQueryData(u, i);
      },
      onPatch: (i) => {
        i && (s && s(i), n.setQueryData(
          u,
          (_ = P(
            void 0
          )) => {
            const E = K(
              O(_),
              i
            );
            return E || n.refetchQueries({ queryKey: u }), E ?? _;
          }
        ));
      }
    }) : void 0
  };
}, ds = ({
  sessionId: e,
  params: t,
  options: n
}) => ({
  ...n,
  mutationFn: (s) => l.updateParticipantMetadata(e, {
    ...t,
    data: s
  })
}), ee = 50, N = (e) => [
  w,
  Ae,
  e
], Rs = (e, {
  limit: t = ee
} = {}) => ({
  queryKey: e ? N(e) : g,
  queryFn: async ({ pageParam: n = 0 }) => l.getSessionsLibraryUpcoming({
    params: {
      limit: t,
      cursor: n * t
    }
  }),
  initialPageParam: 0,
  ...G["15s"],
  getNextPageParam: (n, s) => s.length + 1,
  enabled: !!e
}), tt = (e) => [
  w,
  pe,
  e
], Os = (e, {
  limit: t = ee
} = {}) => ({
  queryKey: e ? tt(e) : g,
  queryFn: async ({ pageParam: n = 0 }) => l.getSessionsLibraryHistory({
    params: {
      limit: t,
      cursor: n * t
    }
  }),
  initialPageParam: 0,
  ...G["30s"],
  getNextPageParam: (n, s) => s.length + 1,
  enabled: !!e
}), fs = ({
  userId: e,
  accessToken: t,
  params: n,
  ...s
}) => ({
  ...s,
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return I.activateTrial(e, {
      ...n,
      data: { token: t }
    });
  }
}), Ts = ({ accessToken: e, params: t, ...n }) => ({
  ...n,
  mutationFn: () => se.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), st = () => [qe], Ks = () => ({
  queryKey: st(),
  queryFn: ne.getPlans,
  ...p["1h"]
}), Ds = (e, t) => [
  h,
  e,
  t
], Fs = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? [h, e, t] : g,
    queryFn: n ? () => re.getStream(t) : void 0,
    enabled: n,
    ...p["15m"],
    gcTime: y["30m"]
  };
}, Ps = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = Ue(e);
  return {
    ...d(y[0]),
    ...t,
    queryKey: s,
    queryFn: ({ signal: r }) => I.getById(e, { ...n, signal: r })
  };
}, nt = (e, t) => [
  Ne,
  e,
  t
], Qs = (e, t, n) => ({
  queryKey: nt(e, t),
  queryFn: () => I.getTransactions(e, {
    params: { includeInvoices: "true", ...n }
  }),
  ...p["3m"]
}), rt = (e) => [fe, e], Ms = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? rt(e) : g,
    queryFn: e ? () => I.getPaymentDetails(e || "") : A,
    ...p["3m"],
    enabled: n
  };
}, ot = () => [Ie], vs = () => ({
  queryKey: ot(),
  queryFn: oe.get,
  ...p["1h"]
}), ut = {
  add: ({ client: e, data: t, userId: n }) => {
    t && C.request.addToPages({
      client: e,
      key: N(n),
      updateFn: (s) => ({
        ...s,
        count: ((s == null ? void 0 : s.count) || 0) + 1,
        sessions: [t, ...(s == null ? void 0 : s.sessions) || []]
      })
    });
  },
  update: ({ client: e, data: t, userId: n }) => {
    t && C.request.updateInPages({
      client: e,
      key: N(n),
      stopOnChange: !0,
      updateFn: (s) => {
        if (!(s != null && s.sessions)) return s;
        const r = s.sessions.findIndex((u) => u.sessionID === t.sessionID);
        if (r === -1) return s;
        const o = {
          ...s,
          sessions: [...s.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({ client: e, userId: t, id: n }) => {
    let s;
    return C.request.updateInPages({
      client: e,
      key: N(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((a) => a.sessionID === n);
        if (o === -1) return r;
        const u = { ...r, sessions: [...r.sessions] };
        return u.count = Math.max(0, (u.count || 1) - 1), s = u.sessions.splice(o, 1)[0], u;
      }
    }), s;
  }
}, at = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: n, sessionRecurrenceID: s } = t[0];
    C.request.update({
      client: e,
      key: [
        L,
        n,
        s,
        D.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var S, M;
        const o = { ...r }, u = (b, B) => (B.type === "domain" ? b[0].push(B) : b[1].push(B), b), [a, i] = ((M = (S = o == null ? void 0 : o.data) == null ? void 0 : S.viewerAccessRules) == null ? void 0 : M.reduce(
          u,
          [[], []]
        )) || [], [_, E] = t.reduce(u, [
          [],
          []
        ]);
        return {
          data: {
            viewerAccessRules: [
              ..._,
              ...a || [],
              ...E,
              ...i || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    C.request.update({
      client: e,
      key: [
        L,
        t,
        n,
        D.GRANTED
      ],
      dataOrFn: (r) => {
        var u;
        const o = ((u = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : u.filter((a) => a.id !== s)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    data: s
  }) => {
    var a;
    const r = U(t, n), o = e.getQueryData(r) || P(void 0), u = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((a = o.data) == null ? void 0 : a.accessRules) || [], ...s]
      }
    };
    e.setQueryData(r, u);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    var E, S;
    const r = U(t, n), o = e.getQueryData(r);
    if (!((E = o == null ? void 0 : o.data) != null && E.accessRules)) return;
    const u = o.data.accessRules, a = u == null ? void 0 : u.findIndex((M) => M.id === s);
    if (a === -1) return;
    const i = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((S = o.data) == null ? void 0 : S.accessRules) || []]
      }
    }, _ = i.data.accessRules.splice(a, 1)[0];
    return e.setQueryData(r, i), _;
  }
}, qs = {
  session: ut,
  viewerAccess: at
}, Ns = (e) => {
  const t = ae(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: u, data: a } = t;
  return T(() => {
    o && n && n(a);
  }, [a, o, n]), T(() => {
    r && s && s(u);
  }, [u, r, s]), t;
}, Ys = (e) => {
  const t = ie(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: u, data: a } = t;
  return T(() => {
    o && n && n(a);
  }, [a, o, n]), T(() => {
    r && s && s(u);
  }, [u, r, s]), t;
}, it = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, Hs = (e, t) => {
  const n = ye(), s = {
    queryKey: e,
    ...it,
    ...t
  }, r = Ee(s);
  r.current = s;
  const [o, u] = W(
    () => new $(n, s)
  ), [a, i] = W(
    o == null ? void 0 : o.getOptimisticResult(s)
  );
  T(() => {
    if (!(e != null && e.length)) return;
    const E = new $(n, r.current);
    u(E);
    const S = E.getOptimisticResult(r.current);
    i(S);
  }, [n, e]), T(() => {
    const E = o.subscribe((S) => {
      i(S);
    });
    return () => {
      E();
    };
  }, [o]);
  const _ = _e(() => {
    const E = o.getOptimisticResult(r.current);
    i(E);
  }, [o]);
  return { ...a, refresh: _ };
};
export {
  X as CACHE_KEY_CALENDAR_EVENT,
  ce as CACHE_KEY_CMS_PAGE,
  Ce as CACHE_KEY_CMS_PAGES,
  mt as CACHE_KEY_CURRENT_USER,
  pe as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  Ae as CACHE_KEY_DASHBOARD_SESSIONS,
  ct as CACHE_KEY_FEATURED_SESSIONS,
  qt as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Ge as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  Ht as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  It as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  Nt as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  Yt as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  vt as CACHE_KEY_HUBSPOT_PROVIDER,
  Ct as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  pt as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  At as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  He as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  le as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  ve as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  de as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  lt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  Re as CACHE_KEY_MEETING_MEMORY_THREAD,
  Oe as CACHE_KEY_MEETING_MEMORY_THREADS,
  fe as CACHE_KEY_PAYMENT_DETAILS,
  w as CACHE_KEY_PRIVATE_BASE,
  Te as CACHE_KEY_RECORDING,
  dt as CACHE_KEY_RECORDING_STATUS,
  Dt as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  Kt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Qt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  Mt as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  Ft as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Pt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Tt as CACHE_KEY_SALESFORCE_PROVIDER,
  Ke as CACHE_KEY_SESSION,
  Rt as CACHE_KEY_SESSION_ACCESS_RULE,
  L as CACHE_KEY_SESSION_ACCESS_RULES,
  De as CACHE_KEY_SESSION_AI_FEED,
  Fe as CACHE_KEY_SESSION_END_SUMMARY,
  Pe as CACHE_KEY_SESSION_EPISODES,
  Me as CACHE_KEY_SESSION_GET_CARDS,
  Ot as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  Qe as CACHE_KEY_SESSION_PRESENCE,
  Z as CACHE_KEY_SESSION_REQUEST_ACCESS,
  ft as CACHE_KEY_SESSION_VIEWERS,
  h as CACHE_KEY_STREAM_TOKEN,
  qe as CACHE_KEY_SUBSCRIPTION_PLANS,
  Ne as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  Ye as CACHE_KEY_USER,
  Ie as CACHE_KEY_USER_MEETING_TYPES,
  fs as activateTrialMutation,
  G as activeOptions,
  Lt as aiFeedCacheService,
  jt as aiFeedEventMutation,
  d as baseBraidOptions,
  Gt as buildPrivateQueryKey,
  C as cacheService,
  Xt as calendarEventQuery,
  ht as cmsPageQuery,
  es as cmsPagesQuery,
  ss as createCRMBindingMutation,
  $t as disabledFetchOptions,
  g as emptyCacheKey,
  A as emptyFn,
  As as endSessionMutation,
  m as getActiveOptions,
  q as getAiFeedQueryKey,
  ts as getCRMProviderBindingsForSessionQuery,
  ze as getCRMProviderBindingsForSessionQueryKey,
  Jt as getCalendarEventQueryKey,
  Zt as getCardsQuery,
  $e as getCardsQueryKey,
  We as getCmsPageQueryKey,
  je as getCmsPagesQueryKey,
  c as getInactiveOptions,
  xt as getInfinityRequestData,
  Xe as getIntegrationsProvidersQueryKey,
  Bt as getListeningModeSpeakersQueryKey,
  f as getMeetingMemoryThreadMessagesQueryKey,
  bt as getMeetingMemoryThreadQueryKey,
  x as getMeetingMemoryThreadsQueryKey,
  tt as getPastSessionQueryKey,
  rt as getPaymentDetailsQueryKey,
  Ks as getPlansQuery,
  st as getPlansQueryKey,
  wt as getQueryRequestData,
  Ze as getRecordingEpisodesQueryKey,
  he as getRecordingQueryKey,
  Ve as getRequestData,
  Wt as getSessionAiFeedQuery,
  ke as getSessionEndSummaryQueryKey,
  et as getSessionGrantedAccessRulesQueryKey,
  Le as getSessionPresenceKey,
  Cs as getSessionQuery,
  Be as getSessionQueryKey,
  Ds as getStreamTokenQueryKey,
  N as getUpcomingSessionQueryKey,
  ot as getUserMeetingTypesQueryKey,
  Ue as getUserQueryKey,
  nt as getUserTransactionsQueryKey,
  Ts as hostOptInMutation,
  Je as hubspotGetCompanyQueryKey,
  ns as hubstopGetCompanyQuery,
  p as inactiveOptions,
  kt as initConfig,
  rs as integrationsProvidersQuery,
  K as jsonPatch,
  Vt as jsonPatchArrayFallbackValidator,
  R as logger,
  Ut as meetingMemoryCacheService,
  is as meetingMemoryDeleteThreadMutation,
  as as meetingMemoryThreadMessagesQuery,
  us as meetingMemoryThreadQuery,
  os as meetingMemoryThreadsQuery,
  y as milliseconds,
  Os as pastSessionQuery,
  Ms as paymentDetailsQuery,
  it as queryDataDefaultOptions,
  ys as recordingEpisodesQuery,
  Es as recordingQuery,
  gs as requestAccessMutation,
  qs as sessionCacheService,
  zt as sessionEndSummaryQuery,
  cs as sessionGrantedAccessRulesQuery,
  _s as sessionPendingAccessQuery,
  U as sessionPendingAccessQueryKey,
  ls as sessionPresenceQuery,
  Fs as streamTokenQuery,
  Rs as upcomingSessionQuery,
  ds as updateParticipantMetadataMutation,
  ms as updateSessionAccessMutation,
  ps as updateSessionMutation,
  Ns as useInfiniteQuery,
  Ys as useQuery,
  Hs as useQueryData,
  vs as userMeetingTypesQuery,
  Ps as userQuery,
  Ss as userSessionRequestAccessQuery,
  be as userSessionRequestAccessQueryKey,
  Qs as userTransactionsQuery
};
